import classNames from "classnames";
import React from "react";

const SectionTitle = ({ title, className, ...props }) => {
  const finalClassName = classNames(
    "col-start-2 md:col-start-3 col-span-8 md:col-span-6 justify-center inter text-neutral-600 ",
    className
  );
  return (
    <div className={finalClassName} {...props}>
      <h3 className="text-center text-4xl md:text-6xl font-bold">{title}</h3>
    </div>
  );
};

export default SectionTitle;

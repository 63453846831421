import React from "react";
import classNames from "classnames";
import SectionTitle from "../components/SectionTitle";
import { useTranslation } from "react-i18next";
const AboutMe = ({ innerRef, id }) => {
  const { t } = useTranslation();

  const liClassName = classNames("my-1");

  return (
    <div
      className="h-screen grid grid-cols-10 text-pretty  md:pt-52 pt-24"
      ref={innerRef}
      id={id}
    >
      <SectionTitle title={t("aboutMe")} />
      {/* PERSONAL INFORMATION CARD WITH PICTURE */}
      <div className="col-start-1 col-span-10 text-neutral-600">
        <div className="flex justify-center">
          <div>
            <div className="w-full flex justify-center">
              <img
                src="./img/Profile.webp"
                className="w-32 md:w-44 lg:w-60 rounded-full rounded-t-full z-10 aspect-square"
                alt="Matías Dupraz"
              />
            </div>
            <div className=" rounded-r-3xl flex font-mono pr-4">
              <ul className="text-center text-sm md:text-lg lg:text-xl font-medium tracking-wider leading-6 md:leading-10 ml-2">
                <li
                  className={
                    "my-1 font-bold text-lg md:text-xl lg:text-2xl text-neutral-900"
                  }
                >
                  Matías Dupraz
                </li>
                <li className={liClassName}>{t("systemsAnalyst")}</li>
                <li className={liClassName}>{t("webDeveloper")}</li>
                <li className={liClassName}>{t("languages")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-start-2 md:col-start-3 col-span-8 md:col-span-6 justify-center inter text-neutral-600 ">
        <p className="text-center text-md md:text-lg lg:text-xl font-medium tracking-wider leading-6 md:leading-10">
          {t("presentationText")}
        </p>
      </div>
    </div>
  );
};

export default AboutMe;

import { useEffect, useRef, useState } from "react";

function useTypingEffect(text, interKeyStrokeDuration, condition) {
  const [currentPosition, changeCurrentPosition] = useState(0);
  const currentPositionRef = useRef(0);
  const [currentTextIndex] = useState(0);

  useEffect(() => {
    const timeoutIds = [];

    const typeText = (textIndex) => {
      const intervalId = setInterval(() => {
        if (currentPositionRef.current < text.length) {
          changeCurrentPosition((value) => value + 1);
          currentPositionRef.current += 1;
        } else {
          clearInterval(intervalId);

          // Additional logic to handle completion
        }
      }, interKeyStrokeDuration);
    };

    // Check if the condition is true before starting typing
    if (condition) {
      // Set the delay before starting typing
      timeoutIds.push(
        setTimeout(() => {
          typeText(currentTextIndex);
        }, 1000) // 1000 milliseconds = 1 second
      );
    }

    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [interKeyStrokeDuration, text, currentTextIndex, condition]);

  return text.substring(0, currentPosition);
}

export default useTypingEffect;
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Squash } from "hamburger-react";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const NavBar = ({ sections }) => {
  const { t, i18n } = useTranslation();
  const languages = ["EN", "ES", "IT"];
  const languageCodes = ["en-US", "es-US", "it-IT"];

  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [displayedLang, setDisplayedLang] = useState("EN");
  useEffect(() => {
    const langCode = i18n.language.split("-")[0].toUpperCase(); // Extract language code and convert to uppercase
    const matchedLang = languages.find((lang) => lang === langCode);
    if (matchedLang) {
      setSelectedLanguage(languages.indexOf(matchedLang));
      setDisplayedLang(matchedLang);
    } else {
      // If language not found in predefined list, fallback to default language
      setDisplayedLang(languages[0]);
      setSelectedLanguage(0);
    }
  }, [i18n.language]);

  const handleChangeLang = () => {
    if (selectedLanguage < languages.length - 1) {
      i18n.changeLanguage(languageCodes[selectedLanguage + 1]);
      setSelectedLanguage(selectedLanguage + 1);
    } else {
      i18n.changeLanguage(languageCodes[0]);
      setSelectedLanguage(0);
    }
  };

  const {
    homeIsVisible,
    aboutIsVisible,
    myWorkIsVisible,
    experienceIsVisible,
    contactIsVisible,
  } = sections;

  const linkClassName = classNames(
    "font-bold text-neutral-500 hover:text-neutral-800 inline-block  ease-linear transition-all duration-200"
  );
  const hoveredLinkClassName = classNames(
    "font-bold inline-block text-neutral-800 bg-clip-text ease-linear transition-all duration-200"
  );
  const mobileLinkClassName = classNames(
    "block px-4 py-2 text-neutral-700 inter semi-bold tracking-widest hover:drop-shadow-lg rounded"
  );

  const hoveredMobileLinkClassName = classNames(
    "block px-4 py-2 text-neutral-700 inter semi-bold tracking-widest hover:drop-shadow-lg rounded"
  );

  const [isClicked, setIsClicked] = useState(false);
  const mobileDrawerClassName = classNames(
    "mobile-menu block md:hidden",
    isClicked ? "block" : "hidden"
  );

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <header
      className="bg-gradient-to-r bg-stone-100 shadow shadow-stone-200 fixed w-full z-40 px-10 md:px-0 bg-opacity-50 backdrop-blur-md"
      data-aos="fade-down"
    >
      <nav className="container mx-auto ">
        <div className="flex items-center justify-between md:mx-5">
          <HashLink to="#home" smooth={"true"}>
            <div className="text-white font-bold text-xl">
              <img
                src="./img/MD.webp"
                className="h-14 lg:h-20 opacity-70"
                alt="MATÍAS DUPRAZ"
              />
            </div>
          </HashLink>

          <div className="hidden md:block">
            <ul className="flex items-center space-x-8">
              <li>
                <HashLink to="#home" smooth={"true"}>
                  <h2
                    className={
                      homeIsVisible ? hoveredLinkClassName : linkClassName
                    }
                  >
                    {homeIsVisible ? `< ${t("home")} />` : t("home")}
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#aboutMe" smooth={"true"}>
                  <h2
                    className={
                      !homeIsVisible && aboutIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    {!homeIsVisible && aboutIsVisible
                      ? `< ${t("aboutMe")} />`
                      : t("aboutMe")}
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#myWork" smooth={"true"}>
                  <h2
                    className={
                      !aboutIsVisible && myWorkIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    {!aboutIsVisible && myWorkIsVisible
                      ? `< ${t("myWork")} />`
                      : t("myWork")}
                  </h2>
                </HashLink>
              </li>

              <li>
                <HashLink to="#experience" smooth={"true"}>
                  <h2
                    className={
                      !myWorkIsVisible && experienceIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    {!myWorkIsVisible && experienceIsVisible
                      ? `< ${t("experience")} />`
                      : t("experience")}
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#contact" smooth={"true"}>
                  <h2
                    className={
                      !experienceIsVisible && contactIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    {!experienceIsVisible && contactIsVisible
                      ? `< ${t("contact")} />`
                      : t("contact")}
                  </h2>
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="md:hidden flex">
            <button
              aria-label="Language change button"
              className="h-4 w-4 mx-5 mb-1 font-bold self-center text-neutral-500 hover:text-neutral-800 ease-linear transition-all duration-200  "
              onClick={() => handleChangeLang()}
            >
              {displayedLang}
            </button>
            <button
              className="outline-none mobile-menu-button text-stone-700 self-center"
              onClick={() => handleClick()}
              aria-label="Navigation button"
            >
              <Squash toggled={isClicked} />
            </button>
          </div>
          <div className="text-end md:inline-block hidden">
            <button
              className="h-4 w-4 font-bold text-neutral-500 hover:text-neutral-800 ease-linear transition-all duration-200  "
              onClick={() => handleChangeLang()}
              aria-label="Language change button"
            >
              {displayedLang}
            </button>
          </div>
        </div>
        <div className={mobileDrawerClassName}>
          <ul className="mt-4 space-y-4">
            <li>
              <HashLink
                to="#home"
                smooth={"true"}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                <h2
                  className={
                    homeIsVisible
                      ? hoveredMobileLinkClassName
                      : mobileLinkClassName
                  }
                >
                  {homeIsVisible ? `< ${t("home")} />` : t("home")}
                </h2>
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#aboutMe"
                smooth={"true"}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                <h2
                  className={
                    !homeIsVisible && aboutIsVisible
                      ? hoveredMobileLinkClassName
                      : mobileLinkClassName
                  }
                >
                  {!homeIsVisible && aboutIsVisible
                    ? `< ${t("aboutMe")} />`
                    : t("aboutMe")}
                </h2>
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#myWork"
                smooth={"true"}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                <h2
                  className={
                    !aboutIsVisible && myWorkIsVisible
                      ? hoveredMobileLinkClassName
                      : mobileLinkClassName
                  }
                >
                  {!aboutIsVisible && myWorkIsVisible
                    ? `< ${t("myWork")} />`
                    : t("myWork")}
                </h2>
              </HashLink>
            </li>

            <li>
              <HashLink
                to="#experience"
                smooth={"true"}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                <h2
                  className={
                    !myWorkIsVisible && experienceIsVisible
                      ? hoveredMobileLinkClassName
                      : mobileLinkClassName
                  }
                >
                  {!myWorkIsVisible && experienceIsVisible
                    ? `< ${t("experience")} />`
                    : t("experience")}
                </h2>
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#contact"
                smooth={"true"}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                <h2
                  className={
                    !experienceIsVisible && contactIsVisible
                      ? hoveredMobileLinkClassName
                      : mobileLinkClassName
                  }
                >
                  {!experienceIsVisible && contactIsVisible
                    ? `< ${t("contact")} />`
                    : t("contact")}
                </h2>
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;

import React from "react";
import TextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";
import Icon from "../components/Icon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Home = ({ innerRef, id }) => {
  const { t } = useTranslation();
  const TEXTS = [
    t("systemsAnalyst"),
    t("frontDev"),
    t("juniorFullDev"),
    t("UXUI"),
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div
      className="justify-center h-screen content-center"
      ref={innerRef}
      id={id}
    >
      <h1 className="text-4xl md:text-6xl text-center  text-neutral-600 inter font-bold tracking-wider hover:text-neutral-700 ease-linear transition-all duration-400">
        Matías Dupraz
      </h1>
      <h2 className="text-3xl md:text-5xl text-center text-neutral-500 inter font-bold tracking-wider mt-10">
        <TextTransition
          className="justify-center"
          springConfig={presets.wobbly}
        >
          {TEXTS[index % TEXTS.length]}
        </TextTransition>
      </h2>
      <div className="flex justify-center mt-10">
        <Link to={"https://github.com/MatiasDupraz"}>
          <Icon
            disableHover
            github
            className={
              "h-8 md:h-10 text-neutral-600 hover:text-neutral-800 mx-2 ease-linear transition-all duration-400"
            }
          />
        </Link>
        <Link to={"https://www.linkedin.com/in/matias-dupraz/"}>
          <Icon
            disableHover
            linkedin
            className={
              "h-8 md:h-10 text-neutral-600 hover:text-neutral-800 mx-2 ease-linear transition-all duration-400"
            }
          />
        </Link>
        <Link to={"mailto:matias.dupraz@gmail.com"}>
          <Icon
            disableHover
            mail
            className={
              "h-8 md:h-10 text-neutral-600 hover:text-neutral-800 mx-2 ease-linear transition-all duration-400"
            }
          />
        </Link>
      </div>
      <div className="text-center my-10 inter italic text-xl text-neutral-600 justify-center mx-10">
        <div className=" flex justify-center">
          <blockquote className="relative">
            <svg
              className="absolute -top-6 -start-8 size-16 text-gray-200"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                fill="currentColor"
              ></path>
            </svg>

            <div className="relative z-10">
              <p className="text-neutral-700 text-base md:text-xl">
                <em>{t("tracyQuote")}</em>
              </p>
            </div>
          </blockquote>
        </div>
        <p className="text-base md:text-xl mt-4">- Brian Tracy</p>
      </div>
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import EmblaCarousel from "../components/Carousel";
import "../css/embla.css";
import Icon from "../components/Icon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const MyWork = ({ innerRef, id }) => {
  const { t } = useTranslation();
  const [html, setHtml] = useState(false);
  const [css, setCss] = useState(false);
  const [js, setJs] = useState(false);
  const [ts, setTs] = useState(false);
  const [node, setNode] = useState(false);
  const [react, setReact] = useState(false);
  const [tailwind, setTailwind] = useState(false);
  const [bootstrap, setBootstrap] = useState(false);
  const [git, setGit] = useState(false);
  const [mysql, setMysql] = useState(false);

  const htmlClass = classNames(
      "h-8 md:h-10 self-center",
      html ? "text-orange-600" : "text-neutral-600"
    ),
    cssClass = classNames(
      "h-8 md:h-10 self-center",
      css ? "text-blue-600" : "text-neutral-600"
    ),
    jsClass = classNames(
      "h-8 md:h-10 self-center",
      js ? "text-yellow-400" : "text-neutral-600"
    ),
    tsClass = classNames(
      "self-center",
      ts ? "text-cyan-700" : "text-neutral-600"
    ),
    nodeClass = classNames(
      "h-8 md:h-10 self-center",
      node ? "text-green-600" : "text-neutral-600"
    ),
    reactClass = classNames(
      "h-8 md:h-10 self-center",
      react ? "text-sky-300" : "text-neutral-600"
    ),
    tailwindClass = classNames(
      "self-center",
      tailwind ? "text-blue-300" : "text-neutral-600"
    ),
    bootstrapClass = classNames(
      "h-8 md:h-10 self-center",
      bootstrap ? "text-purple-600" : "text-neutral-600"
    ),
    gitClass = classNames(
      "h-8 md:h-10 self-center",
      git ? "text-orange-700" : "text-neutral-600"
    ),
    mysqlClass = classNames("self-center", mysql ? "text-blue-500" : "");

  const slides = [
    <div className="flex content-center justify-center mt-10 embla__slide ">
      <div>
        <img
          src="./img/work/MYDIS_1.webp"
          className="md:h-80 shadow-md shadow-neutral-500 select-none"
          alt="MYDIS.COM Large Img"
        />
      </div>
      <div>
        <img
          src="./img/work/MYDIS_2.webp"
          className="md:w-40 z-10 -ml-10 mt-24 shadow-md shadow-neutral-500 select-none"
          alt="MYDIS.COM Small Img"
        />
      </div>
    </div>,
    <div className="flex content-center justify-center mt-10 embla__slide ">
      <div>
        <img
          src="./img/work/STANG_1.webp"
          className="md:h-80 shadow-md shadow-neutral-500 select-none"
          alt="STANGAGROINSUMOS.COM Large Img"
        />
      </div>
      <div>
        <img
          src="./img/work/STANG_2.webp"
          className="md:w-40 z-10 -ml-10 mt-24 shadow-md shadow-neutral-500 select-none"
          alt="STANGAGROINSUMOS.COM Small Img"
        />
      </div>
    </div>,
    <div className="flex content-center justify-center mt-10 embla__slide">
      <div>
        <img
          src="./img/work/SMI_Soluciones_1.webp"
          className="md:h-80 shadow-md shadow-neutral-500 select-none"
          alt="SMISOLUCIONES.COM Large Img"
        />
      </div>
      <div>
        <img
          src="./img/work/SMI_Soluciones_2.webp"
          className="md:w-40 z-10 -ml-10 mt-24 shadow-md shadow-neutral-500 select-none"
          alt="SMISOLUCIONES.COM Small Img"
        />
      </div>
    </div>,
    <div className="flex content-center justify-center mt-10 embla__slide ">
      <div>
        <img
          src="./img/work/Portfolio-Old_1.webp"
          className="md:h-80 shadow-md shadow-neutral-500 select-none"
          alt="MATIASDUPRAZ.COM Large Img"
        />
      </div>
      <div>
        <img
          src="./img/work/Portfolio-Old_2.webp"
          className="md:w-40 z-10 -ml-10 mt-24 shadow-md shadow-neutral-500 select-none"
          alt="MATIASDUPRAZ.COM Small Img"
        />
      </div>
    </div>,
  ];

  return (
    <div
      className="grid grid-cols-10 text-pretty md:pt-52 pt-24"
      ref={innerRef}
      id={id}
    >
      <SectionTitle title={t("myWork")} />
      <div className="col-start-2 md:col-start-3 col-span-8 md:col-span-6 justify-center inter text-neutral-600 mt-5">
        <div className="flex justify-center">
          <div>
            <p className="text-center text-md md:text-lg lg:text-xl font-medium tracking-wider leading-6 md:leading-10">
              {t("projectPresentation")}
            </p>
            <EmblaCarousel slides={slides} options={{ loop: true }} />
            <p className="text-center text-md md:text-lg lg:text-xl font-medium tracking-wider leading-6 md:leading-10">
              {t("usedTech")}
            </p>
            <div className="md:flex mb-10">
              <div className="flex justify-center mt-5">
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setHtml(true)}
                  onMouseLeave={() => {
                    setHtml(false);
                  }}
                >
                  <Icon disableHover html className={htmlClass} />
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setCss(true)}
                  onMouseLeave={() => {
                    setCss(false);
                  }}
                >
                  <Icon disableHover css className={cssClass} />
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setJs(true)}
                  onMouseLeave={() => {
                    setJs(false);
                  }}
                >
                  <Icon disableHover js className={jsClass} />
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 rounded-full bg-white  text h-14 md:white-sky-600 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setTs(true)}
                  onMouseLeave={() => {
                    setTs(false);
                  }}
                >
                  <div className={tsClass}>
                    <Icon disableHover typescript />
                  </div>
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setNode(true)}
                  onMouseLeave={() => {
                    setNode(false);
                  }}
                >
                  <Icon disableHover nodejs className={nodeClass} />
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setReact(true)}
                  onMouseLeave={() => {
                    setReact(false);
                  }}
                >
                  <Icon disableHover react className={reactClass} />
                </div>

                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1  aspect-square  flex justify-center "
                  onMouseEnter={() => setTailwind(true)}
                  onMouseLeave={() => {
                    setTailwind(false);
                  }}
                >
                  <div className={tailwindClass}>
                    <Icon disableHover tailwind />
                  </div>
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setBootstrap(true)}
                  onMouseLeave={() => {
                    setBootstrap(false);
                  }}
                >
                  <Icon disableHover bootstrap className={bootstrapClass} />
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1 aspect-square  flex justify-center"
                  onMouseEnter={() => setGit(true)}
                  onMouseLeave={() => {
                    setGit(false);
                  }}
                >
                  <Icon disableHover git className={gitClass} />
                </div>
                <div
                  className="border border-neutral-300 hover:pb-5 ease-linear duration-300 bg-white rounded-full h-14 md:h-16 mx-1  aspect-square  flex justify-center "
                  onMouseEnter={() => setMysql(true)}
                  onMouseLeave={() => {
                    setMysql(false);
                  }}
                >
                  <div className={mysqlClass}>
                    <Icon disableHover mysql />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWork;

import { HashRouter as Router } from "react-router-dom";
import "./translation/i18n";
import "./App.css";
import NavBar from "./components/NavBar";
import { useInView } from "react-intersection-observer";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import MyWork from "./pages/MyWork";
import Experience from "./pages/Experience";
import Contact from "./pages/Contact";

function App() {
  const { ref: homeRef, inView: homeIsVisible } = useInView();
  const { ref: aboutRef, inView: aboutIsVisible } = useInView();
  const { ref: myWorkRef, inView: myWorkIsVisible } = useInView();
  const { ref: experienciaRef, inView: experienceIsVisible } = useInView();
  const { ref: contactoRef, inView: contactIsVisible } = useInView();

  return (
    <Router>
      <div className="bg-stone-50">
        <NavBar
          sections={{
            homeIsVisible,
            aboutIsVisible,
            myWorkIsVisible,
            experienceIsVisible,
            contactIsVisible,
          }}
        />
        <Home innerRef={homeRef} id="home" />
        <AboutMe innerRef={aboutRef} id="aboutMe" />
        <MyWork innerRef={myWorkRef} id="myWork" />
        <Experience innerRef={experienciaRef} id="experience" />
        <Contact innerRef={contactoRef} id="contact" />
      </div>
    </Router>
  );
}

export default App;

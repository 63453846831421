import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const TimeLine = ({ className }) => {
  const { t } = useTranslation();
  const finalClassName = classNames(
    "relative flex flex-col justify-center overflow-hidden",
    className
  );
  const titleClassName = classNames(
    "font-medium inter text-stone-600 mb-1 sm:mb-0"
  );
  const bigTittleClassName = classNames(
    "text-xl inter font-bold text-neutral-800"
  );
  const textClassName = classNames("text-neutral-600 inter");
  const dateClassName = classNames(
    "sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-stone-100 rounded-full"
  );
  const dotClassName = classNames(
    "flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-stone-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5 after:animate-pulse"
  );
  return (
    <section className={finalClassName}>
      <div className="w-full max-w-6xl mx-auto px-4 md:px-6">
        <div className="flex flex-col justify-center divide-y divide-slate-200 [&>*]:py-16">
          <div className="w-full max-w-3xl mx-auto">
            <div className="-my-6">
              <div className="relative pl-8 sm:pl-32 py-6 group">
                <div className={titleClassName}>{t("MYDIS")}</div>

                <div className={dotClassName}>
                  <time className={dateClassName}>Feb, 2019</time>
                  <div className={bigTittleClassName}>{t("itTechnician")}</div>
                </div>

                <div className={textClassName}>
                  <p>{t("itTechP1")}</p>
                  <p>{t("itTechP2")}</p>
                </div>
              </div>

              <div className="relative pl-8 sm:pl-32 py-6 group">
                <div className={titleClassName}>{t("SosSalud")}</div>

                <div className={dotClassName}>
                  <time className={dateClassName}>May, 2020</time>
                  <div className={bigTittleClassName}>{t("itSupport")}</div>
                </div>

                <div className={textClassName}>{t("itSupText")}</div>
              </div>

              <div className="relative pl-8 sm:pl-32 py-6 group">
                <div className={titleClassName}>Freelance </div>

                <div className={dotClassName}>
                  <time className={dateClassName}>Jun, 2021</time>
                  <div className={bigTittleClassName}>{t("frontDev")}</div>
                </div>
                <div className={textClassName}>{t("freelanceText")}</div>
              </div>

              <div className="relative pl-8 sm:pl-32 py-6 group">
                <div className={titleClassName}>{t("MYDIS")}</div>

                <div className={dotClassName}>
                  <time className={dateClassName}>May, 2023</time>
                  <div className={bigTittleClassName}>{t("fullstackDev")}</div>
                </div>
                <div className={textClassName}>
                  <p>{t("fullstack1")}</p>
                  <p>{t("fullstack2")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimeLine;

import React from "react";
import SectionTitle from "../components/SectionTitle";
import useTypingEffect from "../hooks/typing-effect-for-text";
import Icon from "../components/Icon";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const Contact = ({ innerRef, id }) => {
  const { ref: textRef, inView: textIsVisible } = useInView();
  const { t } = useTranslation();

  const greetsText = t("contactText");
  const typingEffect = useTypingEffect(greetsText, 50, textIsVisible);

  const copyToClipboard = () => {
    navigator.clipboard.writeText("matias.dupraz@gmail.com");
  };

  return (
    <div
      className="h-screen grid grid-cols-10 text-pretty mt-10  md:pt-52 pt-24"
      ref={innerRef}
      id={id}
    >
      <SectionTitle title={t("contactTitle")} />

      <div className="col-start-2 md:col-start-3 col-span-8 md:col-span-6 justify-center inter text-neutral-600 mt-5 md:mt-0">
        <div className="flex justify-center">
          <div className="border-stone-400 rounded-md text-md md:text-lg lg:text-xl w-fit flex justify-between bg-amber-950 bg-opacity-5">
            <p className=" py-0.5 px-3 md:px-5 lg:px-10 tracking-wider font-mono leading-6 md:leading-10">
              matias.dupraz@gmail.com{" "}
            </p>
            <button
              onClick={copyToClipboard}
              className="bg-stone-400  py-1 px-3 flex rounded-r-md"
              aria-label="Copy mail to clipboard"
            >
              <Icon copy className={"h-5 md:h-6 text-stone-600 self-center"} />
            </button>
          </div>
        </div>
        <a
          href={`/resumes/${t("lang")}/Matías_Dupraz_Web_Developer.pdf`}
          download={"Matías_Dupraz_Web_Developer.pdf"}
        >
          <div className=" flex justify-center">
            <Icon file disableHover className={"h-12 md:h-16 mt-10"} />
          </div>

          <p className="text-center self-center font-mono text-lg">
            {t("resume")}
          </p>
        </a>
        <p className="flex justify-center">{t("resumeText")}.</p>
        <p
          className="text-center text-md md:text-lg lg:text-xl font-medium tracking-wider leading-6 md:leading-10 h-32 md:h-20  mt-10"
          ref={textRef}
        >
          {typingEffect}
          <span className="animate-pulse">|</span>
        </p>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import TimeLine from "../components/TimeLine";
import SectionTitle from "../components/SectionTitle";
import { useTranslation } from "react-i18next";
const Experience = ({ innerRef, id }) => {
  const { t } = useTranslation();
  return (
    <div
      className=" grid grid-cols-10 text-pretty mt-10 md:pt-52 pt-24"
      ref={innerRef}
      id={id}
    >
      <SectionTitle title={t("experience")} />
      <TimeLine className="col-start-2 md:col-start-3 col-span-8 md:col-span-6 justify-center inter text-neutral-600 mt-5 md:mt-0" />
    </div>
  );
};

export default Experience;
